body {
  margin: 0;
  background: rgb(250,250,250);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .016em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modals.dimmer {
  background-color: rgba(0,0,0,0.3);
}

::-webkit-scrollbar {
  display: none;
}

.ui.segment.pushable {
  border: none !important;
  border-radius: 0;
  margin-top: 0;
}

/* -- */

#main-header {
  will-change: transform;
  transition: transform 500ms linear;
}

.visible-header {
  transform: translateY(0%);
}

.hidden-header {
  transform: translateY(-100%);
}

/* -- */

#whatsapp-link {
  will-change: transform;
  transition: transform 500ms linear;
}

#whatsapp-link.visible {
  transform: translateX(0%);
}

#whatsapp-link.hidden {
  transform: translateX(120%);
}

/* -- */

#mobile-options {
  transition: all 300ms ease-in-out;
}
